import React, { Component } from "react";
import axios from "@/axios";
import { applyVueInReact } from "vuereact-combined";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";

class SimTv extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  constructor() {
    super();
    this.state = {
      message: "",
      reports: "",
      subscriber: "",
      loading: true,
      feasibleAmountList: [
        50, 100, 250, 300, 350, 395, 400, 450, 500, 550, 1000, 2000, 5000, 7000,
        9000, 10000,
      ],
      selectedAmount: "",
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleUserNameChange = (event) => {
    this.setState({ subscriber: event.target.value });
  };

  handleAmountChange = (event) => {
    this.setState({ selectedAmount: event.target.value, message: "" });
  };

  checkSimTvAccount = async (event) => {
    event.preventDefault();
    if (
      this.state.subscriber.length !== 10 &&
      this.state.subscriber.length !== 11 &&
      this.state.subscriber.length !== 16
    ) {
      this.setState({
        message: "SimTv CustomerId / CasId Should be of 10 or 11 or 16 Digits.",
      });
      return;
    }
    this.setState({ loading: true });
    const data = await axios.post("api/v2/services/simtv/checkAccount", {
      Subscriber: this.state.subscriber,
    });
    this.setState({ loading: false, message: "" });
    if (data.data && data.data.response) {
      if (data.data.response.Code !== "000") {
        this.setState({ message: data.data.response.Message });
      } else {
        this.setState({ reports: data.data.response });
      }
    }
  };

  validationBeforePayment = () => {
    if (this.state.selectedAmount === "") {
      this.setState({ message: "Please select the amount." });
      return false;
    }
    return true;
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            <h4> SimTv </h4>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  <div className="fade-animation">
                    <div className="mt-4">
                      <div className="font-weight-bold">
                        Customer Id / Cas Id
                      </div>
                      <div>{this.state.reports.CustomerId}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Customer Name</div>
                      <div>{this.state.reports.CustomerName}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">No Of Tv</div>
                      <div>{this.state.reports.NoOfTv}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">
                        Amount to pay (Rs.)
                      </div>
                      <select
                        className="custom-select"
                        name="simTv-amount"
                        onChange={this.handleAmountChange}
                      >
                        <option value="">-- SELECT AMOUNT --</option>
                        {this.state.feasibleAmountList.map((amount, index) => {
                          return (
                            <option value={amount} key={index}>
                              {amount}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* Payment Division */}
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/simtv/payment"
                        paymentPayload={{
                          Subscriber: this.state.subscriber,
                          Amount: this.state.selectedAmount,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        validationBeforePayment={this.validationBeforePayment}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.CustomerName,
                          "Total Amount to Pay":
                            "Rs. " + this.state.selectedAmount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="form-group">
                      <label>
                        Customer Id / Cas Id<i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Username and press enter"
                        value={this.state.username}
                        onChange={this.handleUserNameChange}
                        maxLength={16}
                      />
                      <button
                        className="btn btn-primary mt-3"
                        onClick={this.checkSimTvAccount}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default SimTv;
